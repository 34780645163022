import React from "react";

const Title = props => {
  var s = props.settings;

  return (
    <div className="row old-centered">
      <h2 className="hp__box__title hp__box__title--background hidden-xs col-sm-12 no-margin-padding">
        {s.background}
      </h2>
      <h3 className="hp__box__title col-xs-12 no-margin-padding">{s.title}</h3>
      <h4 className="hp__box__title hp__box__title--description col-xs-12 no-margin-padding">
        {s.subtitle}
      </h4>
    </div>
  );
};

export default Title;
